import React from "react"
import Layout from "../hoc/Layout"
import classes from "./notesTemplate.module.styl"

import Tippy from '@tippyjs/react'
import { graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import '../styles/tippy.styl'

import ShitIsFuckedUp from '../essays/ShitIsFuckedUp'
import EssayTemplate from '../essays/EssayTemplate'

const NotesTemplate = ({ data: { mdx }, location }) => {

  // --------------------------------------------------
  // TIPPY LINKS

  const tippyLinks = props => {
    let tippyContent;
    let anchorContent;
    let tippyMaxWidth;

    // https://stackoverflow.com/questions/1011317/replace-a-value-if-null-or-undefined-in-javascript
    let type = props[ 'data-type' ] || ' '

    // if external link
    if (/http/.test(props.href)) {
      tippyContent = (
        <a className={ classes.noContentTippy } href={ props.href } target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">{ props.href }</a>
      )
      anchorContent = (
        // <span className={ classes.noContentLink }>{ props.children }</span>
        <a className={ classes.noContentLink } { ...props } target="_blank" rel="noreferrer noopener" rel="noopener noreferrer" />
      )

      tippyMaxWidth = '100%';

    } else {
      // removes anything after #
      const href = props.href.replace(/#.+|\|.+/, '')
      const linkedPage = mdx.outboundReferences.filter((reference) => reference.fields.slug === href)

      console.log("href", href)

      // overrides internal tippy so not stuck in recursive loop
      const transclusionLinks = {
        a: props => <span className={ classes.tippyTransclusionLink } { ...props } />,
      }

      // if internal link and has page
      if (linkedPage.length > 0) {
        tippyContent = (
          <div className={ classes.tippyContentContainer }>
            <h1>{ linkedPage[ 0 ].fields.pageTitle }</h1>
            <MDXProvider components={ transclusionLinks }>
              <MDXRenderer>{ linkedPage[ 0 ].body }</MDXRenderer>
            </MDXProvider>
          </div>
        )
        anchorContent = (
          <a { ...props } />
        )

      } else if (type === 'footnote') {

        tippyContent = (
          <a className={ classes.noContentTippy } href={ props.href } target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">{ props.href }</a>
        )
        anchorContent = (
          // <span className={ classes.noContentLink }>{ props.children }</span>
          <a className={ classes.noContentLink } { ...props } target="_blank" rel="noreferrer noopener" rel="noopener noreferrer" />
        )

      } else {
        // if internal link and no page
        tippyContent = (
          <div className={ classes.noContentTippy }>
            Private page.
          </div>
        )

        anchorContent = (
          <span className={ classes.noContentLink }>{ props.children }</span>
        )
      }

      tippyMaxWidth = '50rem'
    }

    return (
      <Tippy
        className="custom"
        interactive={ true }
        interactiveBorder={ 20 }
        delay={ 50 }
        duration={ [ 50, 50 ] }
        zIndex={ 5 }
        content={ tippyContent }
        maxWidth={ tippyMaxWidth }
      // trigger={ 'click' }
      // hideOnClick={ true }
      >
        { anchorContent }
      </Tippy>
    )
  }

  const customComponents = {
    a: tippyLinks,
    Link,
  }

  const linkedReferencesArr = []

  const transclusionLinks = {
    a: props => <span className={ classes.inboundTransclusionLink } { ...props } />,
  }

  mdx.inboundReferences.forEach((link) => {
    linkedReferencesArr.push(
      <div className={ classes.referenceContainer } key={ link.fields.slug }>
        <Link className={ classes.referencePageTitle } to={ `/${link.fields.slug}` }>{ link.fields.pageTitle }</Link>
        <div className={ classes.referenceContentContainer }>
          <MDXProvider components={ transclusionLinks }>
            <MDXRenderer>{ link.body }</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    )
  })


  let hasLinkedReferences = linkedReferencesArr.length > 0

  let noteContent

  if (mdx.fields.pageType !== 'Essay') {
    noteContent = (
      <React.Fragment>
        <article>
          <h1>{ mdx.fields.pageTitle }</h1>
          <div className={ classes.subtitle }>{ mdx.fields.pageType }</div>
          <MDXProvider components={ customComponents }>
            <MDXRenderer>{ mdx.body }</MDXRenderer>
          </MDXProvider>
          { hasLinkedReferences ? <hr /> : null }
        </article>
        { hasLinkedReferences ? (
          <div className={ classes.linkedReferencesContainer }>
            <h3>Linked References</h3>
            { linkedReferencesArr }
          </div>
        ) : null }
      </React.Fragment>
    )
  } else if (mdx.fields.pageType === 'Essay') {

    switch (mdx.fields.pageTitle) {

      case 'Why Shit Is Fucked Up And What We Might Do About It': {
        noteContent = (
          <ShitIsFuckedUp
            mdx={ mdx }
            MDXProvider={ MDXProvider }
            MDXRenderer={ MDXRenderer }
            customComponents={ customComponents }
          />
        )
        break
      }

      default:
        noteContent = (
          <EssayTemplate
            mdx={ mdx }
            MDXProvider={ MDXProvider }
            MDXRenderer={ MDXRenderer }
            customComponents={ customComponents }
          />
        )
    }

    // noteContent = (
    //   <React.Fragment>
    //     <article>
    //       <h1>{ mdx.fields.pageTitle }</h1>
    //       <div className={ classes.subtitle }>{ mdx.frontmatter.last_updated } &nbsp; · &nbsp; { mdx.frontmatter.time_to_read }</div>
    //       <MDXProvider components={ customComponents }>
    //         <MDXRenderer>{ mdx.body }</MDXRenderer>
    //       </MDXProvider>
    //     </article>
    //     <EndDetails frontmatter={ mdx.frontmatter } />
    //   </React.Fragment>
    // )
  }

  return (
    <Layout location={ location } pageTitle={ mdx.fields.pageTitle } coverImage={ mdx.frontmatter.cover_image }>
      { noteContent }
    </Layout>
  )
}

export default NotesTemplate

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        date(formatString: "Do MMMM YYYY")
        last_updated(formatString: "Do MMMM, YYYY")
        first_published(formatString: "Do MMMM, YYYY")
        word_count
        time_to_read
        cover_image
        cover_image_caption
      }
      fields {
        slug
        pageType
        pageTitle
      }
      inboundReferences {
        ... on Mdx {
          body
          fields {
            slug 
            pageTitle
          }
        }
      }
      outboundReferences {
        ... on Mdx {
          body
          fields {
            slug 
            pageTitle
          }
        }
      }
    }
  }
`

// export const pageQuery = graphql`
//   query($id: String) {
//     mdx(id: { eq: $id }) {
//       body
//       frontmatter {
//         date(formatString: "Do MMMM YYYY")
//       }
//       fields {
//         slug
//         pageType
//         pageTitle
//       }
//       inboundReferences {
//         ... on Mdx {
//           body
//           fields {
//             slug 
//             pageTitle
//           }
//         }
//       }
//       outboundReferences {
//         ... on Mdx {
//           body
//           fields {
//             slug 
//             pageTitle
//           }
//         }
//       }
//     }
//   }
// `
