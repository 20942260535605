import React, { useState } from "react"
import { Link } from "gatsby"
import classes from "./ShitIsFuckedUp.module.styl"
import { isMobile } from "react-device-detect";
import FoldListItem from '../components/FoldListItem';

const ShitIsFuckedUp = ({ MDXProvider, MDXRenderer, customComponents, mdx }) => {

  const checkIfFoldIcon = (e) => {
    console.log(e)
  }

  return (
    <React.Fragment>
      <article>
        <h1>{ mdx.fields.pageTitle }</h1>
        <div className={ classes.subtitle }>{ mdx.frontmatter.last_updated } &nbsp; · &nbsp; { mdx.frontmatter.time_to_read }</div>

        <div>
          <div className={ classes.tocContainer }>
            <span className={ classes.tocTitle }>Table of Contents</span>
            <ul className={ classes.firstListELs }>
              <li className={ classes.firstListELs }><a href="#overview">Overview</a></li>
              <li><a href="#preamble">Preamble</a></li>
              <li><a href="#the-iceberg">The Iceberg</a></li>
              <FoldListItem title="The Engine" link="#the-engine">
                <ul>
                  <FoldListItem title="Root Drivers" link="#root-drivers">
                    <ul>
                      <li><a href="#social-cluster">Social Cluster</a></li>
                      <li><a href="#social-connection">Social Connection</a></li>
                      <li><a href="#social-coherence">Social Coherence</a></li>
                      <li><a href="#governance">Governance </a> </li>
                      <li><a href="#order--predictability">Order & Predictability</a></li>
                      <li><a href="#freedom--autonomy">Freedom & Autonomy</a></li>
                      <li><a href="#scarcity--competition">Scarcity & Competition</a></li>
                      <li><a href="#meaning">Meaning </a></li>
                    </ul>
                  </FoldListItem>
                  <li><a href="#how-to-think-about-thinking-about-root-drivers">How To Think About Thinking About Root Drivers</a></li>
                  <li><a href="#developmental-theory">Developmental Theory</a></li>
                  <li><a href="#friction-points">Friction Points</a></li>
                </ul>
              </FoldListItem>
              <FoldListItem title="The Wheel" link="#the-wheel">
                <ul>
                  <li><a href="#meta-frameworks">Meta-Frameworks</a></li>
                  <FoldListItem title="Thinking More Complexly" link="#thinking-more-complexly">
                    <ul>
                      <li><a href="#both-and">Both-And</a></li>
                      <li><a href="#going-above-and-beneath">Going Above and Beneath</a></li>
                      <li><a href="#the-three-and-four-domains">The Three and Four Domains</a></li>
                    </ul>
                  </FoldListItem>
                  <li><a href="#steal-seduce-the-culture">Steal/ Seduce the Culture</a></li>
                  <FoldListItem title="Things To Work On" link="#things-to-work-on">
                    <ul>
                      <li><a href="#my-theory-of-change">My Theory of Change</a></li>
                      <li><a href="#possible-directions-and-projects">Possible Directions and Projects</a></li>
                    </ul>
                  </FoldListItem>
                </ul>
              </FoldListItem>
              <li><a href="#crucial-questions-that-i-have-no-answers-for-yet">Crucial Questions That I Have No Answers For Yet</a></li>
              <FoldListItem title="Extra Bits" link="#extra-bits">
                <ul>
                  <li><a href="#on-resilience-and-complicated-vs-complex">On Resilience and Complicated vs Complex</a></li>
                  <li><a href="#on-innovation">On Innovation</a></li>
                  <li><a href="#on-control-and-influence">On Control and Influence</a></li>
                  <li><a href="#on-hierarchies">On Hierarchies</a></li>
                  <li><a href="#on-desire-for-rich-and-famous">On Desire for Rich and Famous</a></li>
                </ul>
              </FoldListItem>
            </ul>
          </div>

          <img src={ mdx.frontmatter.cover_image } alt={ 'titanic painting by willy stower' } />
          <figcaption>{ mdx.frontmatter.cover_image_caption }</figcaption>


        </div>


        <MDXProvider components={ customComponents }>
          <MDXRenderer>{ mdx.body }</MDXRenderer>
        </MDXProvider>
      </article>

      <div className={ classes.extraInfo }>
        <div><span className={ classes.label }>First Published :</span>  { mdx.frontmatter.first_published }</div>
        <div><span className={ classes.label }>Last Updated :</span>  { mdx.frontmatter.last_updated }</div>
        <div><span className={ classes.label }>Word Count :</span>  { mdx.frontmatter.word_count }</div>
      </div>
    </React.Fragment >
  )
}

export default ShitIsFuckedUp
