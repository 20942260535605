import React, { useState } from "react"
import classes from "./FoldListItem.module.styl"

const FoldListItem = props => {
  const [ isExpand, setIsExpand ] = useState(false)


  return (
    <li>
      <div className={ classes.titleContainer }>
        <div className={ classes.foldIcon } onClick={ () => setIsExpand(!isExpand) }>{ isExpand ? '-' : '+' }</div>&nbsp;
        <div className={ classes.title }><a href={ props.link }>{ props.title }</a></div>
      </div>
      { isExpand ? props.children : null }
    </li>
  )

}

export default FoldListItem