import React, { useState } from "react"
import { Link } from "gatsby"
import classes from "./EssayTemplate.module.styl"
import { isMobile } from "react-device-detect";

const EssayTemplate = ({ MDXProvider, MDXRenderer, customComponents, mdx }) => {

  return (
    <React.Fragment>
      <article>
        <h1>{ mdx.fields.pageTitle }</h1>
        <div className={ classes.subtitle }>{ mdx.frontmatter.last_updated } &nbsp; · &nbsp; { mdx.frontmatter.time_to_read }</div>

        {/* <div>

          <img src={ mdx.frontmatter.cover_image } alt={ 'titanic painting by willy stower' } />
          <figcaption>{ mdx.frontmatter.cover_image_caption }</figcaption>

        </div> */}

        <MDXProvider components={ customComponents }>
          <MDXRenderer>{ mdx.body }</MDXRenderer>
        </MDXProvider>
      </article>

      <div className={ classes.extraInfo }>
        <div><span className={ classes.label }>First Published :</span>  { mdx.frontmatter.first_published }</div>
        <div><span className={ classes.label }>Last Updated :</span>  { mdx.frontmatter.last_updated }</div>
        <div><span className={ classes.label }>Word Count :</span>  { mdx.frontmatter.word_count }</div>
      </div>
    </React.Fragment >
  )
}

export default EssayTemplate
